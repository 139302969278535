<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div
        class="content-main mb24px"
        style="color: #FF0000"
        v-if="form.checkRemark"
      >核算不通过原因：{{form.checkRemark}}</div>
      <div class="content-main">
        <el-form
          :model="form"
          :rules="typeRules"
          ref="form"
          :inline="true"
          label-position="right"
          :label-width="$config.labelWidth"
        >
          <el-row :gutter="20">
            <el-col :span="24">
              <div class="form-label-base">基本信息</div>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单编号">
                <el-input v-model="form.orderId" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="业务类型">
                <el-input v-model="form.orderType" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="下单人" v-if="form.orderType==='外发'">
                <el-input v-model="form.orderName" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="客户名称" prop="partnerId">
                <el-select
                  v-model="form.partnerId"
                  placeholder="请选择"
                  filterable
                  @change="selectPartner"
                >
                  <el-option
                    v-for="(item, idx) in partnerList"
                    :key="idx"
                    :label="item.partnerShorthand"
                    :value="item.partnerId"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="结算客户" prop="partnerAccountId">
                <el-select v-model="form.partnerAccountId" placeholder="请选择" filterable>
                  <el-option
                    v-for="(item, idx) in partnerList"
                    :key="idx"
                    :label="item.partnerShorthand"
                    :value="item.partnerId"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div class="details-line"></div>
            </el-col>
            <el-col :span="24">
              <div class="form-label-base">运输信息</div>
            </el-col>
            <el-col :span="6">
              <el-form-item label="运输日期" prop="transportTime">
                <el-date-picker
                  v-model="form.transportTime"
                  clearable
                  type="date"
                  value-format="timestamp"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="驾驶员" v-if="form.orderType!=='外发'">
                <el-input v-model="form.driverNameType" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="驾驶员电话" v-if="form.orderType!=='外发'">
                <el-input v-model="form.driverPhone" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="驾驶员" v-if="form.orderType==='外发'">
                <el-input v-model="form.driverName" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="驾驶员电话" v-if="form.orderType==='外发'">
                <el-input v-model="form.driverPhone" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="提货车号" prop="plateNumber">
                <el-select v-model="form.plateNumber" placeholder="请选择" filterable>
                  <el-option
                    v-for="(item, idx) in carInfoList"
                    :key="idx"
                    :label="item.plateNumber"
                    :value="item.plateNumber"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            

            <el-col :span="6">
              <el-form-item label="到货车号" prop="cargoArrivalNumber" v-if="form.orderType!=='外发'">
                <el-input v-model="form.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="提货单号" v-if="form.orderType!=='外发'">
                <el-input v-model="form.cargoDeliveryNumber" maxlength="64"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="中转单号" v-if="form.orderType==='直拨'" prop="cargoTransferNumber">
                <el-input v-model="form.cargoTransferNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item class="empty" label="."  ></el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="发货人" v-if="form.orderType==='外发'||form.orderType==='直拨'" prop="senderName">
                <el-input v-model="form.senderName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="手机号(发)" v-if="form.orderType==='外发'||form.orderType==='直拨'" prop="senderContactPhone">
                <el-input v-model="form.senderContactPhone"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="起运地" v-if="form.orderType==='外发'||form.orderType==='直拨'" prop="adminRegions">
                <el-cascader
                  v-model="form.adminRegions"
                  size="small"
                  :options="regionData"
                  @change="handleRegionChange"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="详细地址(发)" v-if="form.orderType==='外发'||form.orderType==='直拨'" prop="sendAddressDetail">
                <el-input v-model="form.sendAddressDetail"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="收货人" v-if="form.orderType==='外发'||form.orderType==='直拨'" prop="receiverName">
                <el-input v-model="form.receiverName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="手机号(收)" v-if="form.orderType==='外发'||form.orderType==='直拨'" prop="receiverContactPhone">
                <el-input v-model="form.receiverContactPhone"></el-input>
              </el-form-item>
              <!-- <el-form-item label="到货车号" v-if="form.orderType!=='外发'">
            <el-input v-model="form.cargoArrivalNumber"></el-input>
              </el-form-item>-->
            </el-col>
            <el-col :span="6">
              <el-form-item label="目的地" v-if="form.orderType==='外发'||form.orderType==='直拨'" prop="adminRegions_r">
                <el-cascader
                  v-model="form.adminRegions_r"
                  size="small"
                  :options="regionData"
                  @change="handleRegionChangeR"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="详细地址(收)" v-if="form.orderType==='外发'||form.orderType==='直拨'" prop="receiveAddressDetail">
                <el-input v-model="form.receiveAddressDetail"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div class="details-line"></div>
            </el-col>
            <el-col :span="24">
              <div class="form-label-base">货物信息</div>
            </el-col>
            <el-col :span="6">
              <el-form-item label="品名" prop="category">
                <el-select v-model="form.category" placeholder="请选择" filterable>
                  <el-option
                    v-for="(item, idx) in  $enums.CompanyList.list"
                    :key="idx"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="吨数" v-if="form.orderType==='外发'" prop="categoryWeight">
                <el-input v-model="form.categoryWeight" type="Number" @change="changeWeight"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="件数" prop="categoryPiece">
                <el-input v-model="form.categoryPiece" type="Number" @blur="validityNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="体积(立方米)" prop="volume">
                <el-input v-model="form.volume" type="Number" @blur="validityNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="重量(吨)" v-if="form.orderType==='直拨'" prop="categoryWeight">
                <el-input v-model="form.categoryWeight" type="Number" @change="changeWeight"></el-input>
              </el-form-item>
            </el-col>
             <el-col :span="6">
              <el-form-item label="成本运费(元)" prop="costCarrierFee" v-if="form.orderType==='直拨'">
                <el-input v-model="form.costCarrierFee" type="Number"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="货位" v-if="form.orderType==='内转'">
                <el-input v-model="form.cargoLocation" maxlength="64"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="去向" v-if="form.orderType==='内转'">
                <el-input v-model="form.cargoDirection" maxlength="64"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="保管员" v-if="form.orderType==='内转'">
                <el-input v-model="form.keeper" maxlength="64"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="货运员" v-if="form.orderType==='内转'">
                <el-input v-model="form.freightAgent" maxlength="64"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="库位" v-if="form.orderType==='直拨'">
                <el-input v-model="form.warehouseLocation" maxlength="64"></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="6">
              <el-form-item label="资源号" v-if="form.orderType==='直拨'">
                <el-input v-model="form.resourceId" maxlength="64"></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :span="6">
              <el-form-item label="规格" v-if="form.orderType==='直拨'">
                <el-input v-model="form.specification" maxlength="64"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="型号" v-if="form.orderType==='直拨'">
                <el-input v-model="form.steelModel" maxlength="64"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="钢种" v-if="form.orderType==='直拨'">
                <el-input v-model="form.steelType" maxlength="64"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="产地" v-if="form.orderType==='直拨'">
                <el-input v-model="form.steelProduction" maxlength="64"></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="6">
              <el-form-item label="开单员" v-if="form.orderType==='直拨'">
                <el-input v-model="form.partOrder" maxlength="64"></el-input>
              </el-form-item>
            </el-col> -->
            <!-- <el-col :span="6">
              <el-form-item label="收货员" v-if="form.orderType==='直拨'">
                <el-input v-model="form.receivingClerk" maxlength="64"></el-input>
              </el-form-item>
            </el-col> -->
            <!-- <el-col :span="6">
              <el-form-item label="理货员" v-if="form.orderType==='直拨'">
                <el-input v-model="form.tallyClerk" maxlength="64"></el-input>
              </el-form-item>
            </el-col> -->
            <!-- <el-col :span="6">
              <el-form-item label="审核员" v-if="form.orderType==='直拨'">
                <el-input v-model="form.checker" maxlength="64"></el-input>
              </el-form-item>
            </el-col> -->
            <!-- <el-col :span="6">
              <el-form-item label="装卸班组" v-if="form.orderType==='直拨'">
                <el-input v-model="form.loadUploadCrew" maxlength="64"></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :span="24">
              <div class="details-line"></div>
            </el-col>
            <el-col :span="24">
              <div class="form-label-base">费用信息</div>
            </el-col>
            <el-col :span="6">
              <el-form-item label="运费(元)" prop="costCarrierFee" v-if="form.orderType!=='外发'">
                <el-input v-model="form.earningFee" type="Number" @blur="validityNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="拼车费(元)" prop="carpoolingFee" v-if="form.orderType==='直拨'">
                <el-input v-model="form.carpoolingFee" type="Number"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="吊装费(元)" prop="liftingFee" v-if="form.orderType!=='内转'">
                <el-input v-model="form.liftingFee" type="Number" @blur="validityNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="提成系数(%)" prop="driverBonusFee">
                <el-input v-model="form.driverBonusFee" type="Number" @blur="validityNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="收入单价(元)" prop="earningUnitPrice" v-if="form.orderType==='外发'">
                <el-input
                  v-model="form.earningUnitPrice"
                  type="Number"
                  @change="changeEarningUnitPrice"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="收入金额(元)" prop="earningFee" v-if="form.orderType==='外发'">
                <el-input v-model="form.earningFee" type="Number"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="过磅费(元)" prop="weightFee" v-if="form.orderType==='外发'">
                <el-input v-model="form.weightFee" type="Number" @blur="validityNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="卸车费(元)" prop="unloadFee" v-if="form.orderType==='外发'">
                <el-input v-model="form.unloadFee" type="Number" @blur="validityNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="成本单价(元)" prop="costUnitPrice" v-if="form.orderType==='外发'">
                <el-input v-model="form.costUnitPrice" type="Number" @change="changeCostUnitPrice"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="成本运费(元)" prop="costCarrierFee" v-if="form.orderType==='外发'">
                <el-input v-model="form.costCarrierFee" type="Number"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="过路过桥费(元)" prop="tollsFee" v-if="form.orderType==='外发'">
                <el-input v-model="form.tollsFee" type="Number" @blur="validityNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="加油费(元)" prop="fuelFee" v-if="form.orderType==='外发'">
                <el-input v-model="form.fuelFee" type="Number" @blur="validityNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="罚款(元)" prop="fine" v-if="form.orderType==='外发'">
                <el-input v-model="form.fine" type="Number" @blur="validityNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="其他费用(元)" prop="otherFee" v-if="form.orderType==='外发'">
                <el-input v-model="form.otherFee" type="Number" @blur="validityNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="信息费(元)" prop="messageFee" v-if="form.orderType==='外发'">
                <el-input v-model="form.messageFee" type="Number" @blur="validityNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div class="details-line"></div>
            </el-col>
            <el-col :span="24">
              <div class="form-label-base">其他信息</div>
            </el-col>
              <el-col :span="24">
                <el-form-item label="下单备注：">
                  <span>{{this.form.cargoShipmentRemark||'- -'}}</span>
                </el-form-item>
              </el-col>

              <el-col :span="6">
                <el-form-item label="订单备注：">
                  <el-input v-model="form.orderRemark" maxlength="64"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="交单备注：">
                  <span>{{this.form.deliveryRemark||'- -'}}</span>
                </el-form-item>
              </el-col>
   
              <el-col :span="24">
                <el-form-item label="核算备注：">
                  <span>{{this.form.checkRemark||'- -'}}</span>
                </el-form-item>
              </el-col>
          </el-row>
          <div class="details-line"></div>
          <div class="form-label-base">
            图片信息
            <el-button class="upload-btn ml15px">
              <i class="el-icon-plus"></i>上传
              <label for="cover_one" class="upload-label">
                <input
                  type="file"
                  hidden
                  @change="OneUploadCover($event)"
                  accept="image/png, image/jpeg"
                  ref="uploadCoverOne"
                  id="cover_one"
                />
              </label>
            </el-button>
          </div>
          <!--图片-->
          <div
            class="order-img"
            :style="{'margin-right': (idx+1)%4===0?'0px':''}"
            v-for="(item, idx) in bigImgList"
            :key="idx"
          >
            <el-image
              :preview-src-list="imgSrcList"
              :src="bigImages[idx]"
              fit="cover"
              style="width: 100%;height: 100%;border-radius: 4px"
              @click="onPreview(idx)"
              lazy
            ></el-image>
            <div class="img-text">
              <div>
                <span>{{item.userName||'- -'}}</span>
                &nbsp;
                <span>{{item.uploaderPhone||'- -'}}</span>
              </div>
              <span>{{item.gmtCreate|str2ymdhm}}</span>
            </div>
            <div class="img-icon">{{item.imageType}}</div>
            <i
              class="el-icon-error close-icon"
              v-if="item.isClear"
              @click="clickClearImg(item,idx)"
            ></i>
          </div>
        </el-form>
      </div>
      <div class="btn-main">
        <el-button
          type="success"
          class="btn-success"
          @click="clickSubmit('已审核')"
          :loading="btnLoading"
        >审核通过</el-button>
        <el-button type="primary" @click="clickSubmit('保存')" :loading="btnLoading">保存修改</el-button>
        <el-button type="danger" @click="clickInvalid">废 单</el-button>
        <el-button @click="$router.go(-1)">返 回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { regionData, CodeToText, TextToCode } from "element-china-area-data";

export default {
  data() {
      const rules = {
          // 将不可用item修改为可编辑状态   add by alexChen 2020-1-3 start
        partnerId: {
          required: true,
          message: "请选择客户名称",
          trigger: "change",
        },
        partnerAccountId: {
          required: true,
          message: "请选择结算客户",
          trigger: "change",
        },
        transportTime: {
          required: true,
          message: "请选择运输日期",
          trigger: "change",
        },
        plateNumber: {
          required: true,
          message: "请选择提货车号",
          trigger: "change",
        },
        driverBonusFee: [{
          required: true,
          message: "请输入提成系数",
          trigger: "blur",
          
        },{ validator: this.$validator.isTwoDecimal }],
        cargoArrivalNumber: [
          {
            required: true,
            message: "请输入到货车号",
            trigger: "blur",
          },
          { validator: this.$validator.isContainBlank },
        ],
        category: { required: true, message: "请选择品名", trigger: "change" },
        earningFee: [
          { required: true, message: "请输入运费或收入金额", trigger: "blur" },
        ],
      }
    // const vm = this
    return {
      nav: [
        { name: "交单审核", path: "/examine/to-examine" },
        { name: "审核" },
      ],
      // 预览大图
      bigImgList: [],
      bigImages: [],
      imgSrcList: [],
      form: {},
      partnerList: [],
      driverList: [],
      carInfoList: [],
      zxsArray: ["北京市", "天津市", "上海市", "重庆市"],
      regionData: regionData,
      n_rules: {
          ...rules,
      },
          // 直拨
      z_rules: {
        ...rules,
        cargoTransferNumber: [
          {
            required: true,
            message: "请输入中转单号",
            trigger: "blur",
          },
          { validator: this.$validator.isContainBlank },
        ],
        carpoolingFee: [
          { required: true, message: "请输入拼车费", trigger: "blur" },
        ],
      },
      w_rules: {
          ...rules,
          // 外发
        adminRegions: {
          required: true,
          message: "请选择起运地",
          trigger: "change",
        },
        sendAddressDetail: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: "blur",
          },
          { validator: this.$validator.isContainBlank },
        ],
        senderName: [
          {
            required: true,
            message: "请输入发货人",
            trigger: "blur",
          },
          { validator: this.$validator.isContainBlank },
        ],
        senderContactPhone: [
          {
            required: true,
            message: "请输入发货人手机号",
            trigger: "blur",
          },
          { validator: this.$validator.isContainBlank },
        ],
        adminRegions_r: {
          required: true,
          message: "请选择目的地",
          trigger: "change",
        },
        receiveAddressDetail: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: "blur",
          },
          { validator: this.$validator.isContainBlank },
        ],
        receiverName: [
          {
            required: true,
            message: "请输入收货人",
            trigger: "blur",
          },
          { validator: this.$validator.isContainBlank },
        ],
        receiverContactPhone: [
          {
            required: true,
            message: "请输入收货人手机号",
            trigger: "blur",
          },
          { validator: this.$validator.isContainBlank },
        ],
        categoryWeight: [
          {
            required: true,
            message: "请输入吨数",
            trigger: "blur",
          },
          { validator: this.$validator.isThreeDecimal },
        ],
        earningUnitPrice: [
          {
            required: true,
            message: "请输入收入单价",
            trigger: "blur",
          },
          { validator: this.$validator.isTwoDecimal },
        ],
        costUnitPrice: [
          {
            required: true,
            message: "请输入成本单价",
            trigger: "blur",
          },
          { validator: this.$validator.isTwoDecimal },
        ],
        costCarrierFee: [
          {
            required: true,
            message: "请输入成本运费",
            trigger: "blur",
          },
          { validator: this.$validator.isTwoDecimal },
        ],

      },
     
      btnLoading: false,
    };
  },
  methods: {
    // 依次查看大图
    onPreview(index) {
      let arr1 = this.bigImages.filter(
        (arrItem, arrIndex) => index <= arrIndex
      );
      let arr2 = this.bigImages.filter((arrItem, arrIndex) => index > arrIndex);
      let arr3 = arr2.sort((a, b) => {
        b - a;
      });
      this.imgSrcList = [...arr1, ...arr3];
    },

    /**
     * 规范数据输入框
     */
    validityNumber(val) {
      this.$utils.validityNumber(
        this,
        val.target.validationMessage.length,
        val.target.value
      );
    },
    /**
     * 变更吨数自动计算
     * 收入金额(元)
     * 成本运费(元)
     */
    changeWeight(val) {
      this.changeEarningUnit(val);
      this.changeCostUnit(val);
    },
    changeEarningUnit(n) {
      if (!this.form.earningUnitPrice) {
        return;
      } else {
        let tempVal = parseFloat(this.form.earningUnitPrice * n).toFixed(3);
        this.form.earningFee = tempVal.substring(0, tempVal.length - 1);
      }
    },
    changeCostUnit(n) {
      if (!this.form.costUnitPrice) {
        return;
      } else {
        let tempVal = parseFloat(this.form.costUnitPrice * n).toFixed(3);
        this.form.costCarrierFee = tempVal.substring(0, tempVal.length - 1);
      }
    },
    /**
     * 计算收入金额
     */
    changeEarningUnitPrice(val) {
      let tempVal = parseFloat(this.form.categoryWeight * val).toFixed(3);
      this.form.earningFee = tempVal.substring(0, tempVal.length - 1);
    },
    /**
     * 计算成本运费
     */
    changeCostUnitPrice(val) {
      let tempVal = parseFloat(this.form.categoryWeight * val).toFixed(3);
      this.form.costCarrierFee = tempVal.substring(0, tempVal.length - 1);
    },
    /**
     * 起运地省市区
     */
    handleRegionChange(value) {
      var arr = value;
      if (!value) return;
      this.form.sendProvinceName = CodeToText[arr[0]];
      this.form.sendCityName = CodeToText[arr[1]];
      this.form.sendCountyName = CodeToText[arr[2]];
    },
    /**
     * 目的地
     */
    handleRegionChangeR(value) {
      var arr = value;
      if (!value) return;
      this.form.receiveProvinceName = CodeToText[arr[0]];
      this.form.receiveCityName = CodeToText[arr[1]];
      this.form.receiveCountyName = CodeToText[arr[2]];
    },
    /**
     * 选择客户名称
     * 并自动关联结算客户
     */
    selectPartner(val) {
      this.form.partnerAccountId = val;
    },
    calculatedAmount() {
      this.form.earningFee = this.amounts;
      this.form.costCarrierFee = this.freight;
    },
    // 上传图片
    async OneUploadCover(e) {
      if (e.target.files.length === 0) return;
      if (e.target.files[0].size > 1024 * 1024 * 10) {
        this.$message.warning("上传图片不能超过10M");
        return;
      }
      let file = e.target.files[0];
      let formData = new FormData();
      formData.append("file", file);
      formData.append(
        "ailivingToken",
        JSON.parse(sessionStorage.getItem("loginData")).Token
      );
      const fileKey = await this.$api.UploadImg(formData);
      const imageUrl = this.$utils.getObjectURL(file);
      const gmtCreate = this.$dayjs().valueOf();
      this.form.imageInfoVOS.push({
        imageKey: fileKey,
        imageUrl: imageUrl,
        imageType: "补充",
        gmtCreate: gmtCreate,
        isClear: true,
      });
      this.bigImgList.push({
        imageKey: fileKey,
        imageUrl: imageUrl,
        imageType: "补充",
        gmtCreate: gmtCreate,
        isClear: true,
      });
      this.bigImages.push(imageUrl);

      // 不清空input的值，下次选取不到相同的照片
      let oFileInput = document.getElementById("cover_one");
      oFileInput.value = null;
    },
    //  清除照片
    clickClearImg(img, i) {
      this.bigImgList.splice(i, 1);
      this.bigImages.splice(i, 1);
      this.form.imageInfoVOS = this.form.imageInfoVOS.filter((item) => {
        return img.imageKey != item.imageKey;
      });
    },
    // 提交
    clickSubmit(e) {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return;
        this.btnLoading = true;
        const newForm = this.$_.cloneDeep(this.form);
        if (e === "已审核") newForm.orderStatus = "已审核";
        if (e === "保存") newForm.orderStatus = "已交单";
        newForm.imageInfoVOS = [];
        const newImgList = this.form.imageInfoVOS.filter((e) => e.isClear);
        newForm.imageInfoVOS = newImgList.map((e) => {
          return {
            imageUrl: [e.imageKey],
            imageType: e.imageType,
            imageRemark: e.imageRemark,
          };
        });
        if (newForm.imageInfoVOS.length === 0) newForm.imageInfoVOS = null;
        try {
          await this.$api.OrderUpdate({
            type: 1,
            steelOrderInfoVO: newForm,
          });
          this.$notify({
            title: "成功",
            message: `${e === "已审核" ? "操作成功" : "修改成功"}`,
            type: "success",
          });
          this.$router.go(-1);
        } catch (e) {
          // console.log(e);
        }
        this.btnLoading = false;
      });
    },
    async clickInvalid() {
      try {
        await this.$confirm(`是否确认废单？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        await this.$api.deleteOrder({
          orderIdList: [this.$route.query.orderId],
        });
        this.$notify({
          title: "成功",
          message: "操作成功",
          type: "success",
        });
        this.$router.go(-1);
      } catch (e) {
        // console.log(e);
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    to.meta.keepAlive = true;
    next();
  },
  computed: {
    // 成本运费计算
    freight() {
      return Number(this.form.costUnitPrice * this.form.categoryWeight);
    },
    // 收入金额计算
    amounts() {
      return Number(this.form.earningUnitPrice * this.form.categoryWeight);
    },
    typeRules() {
        switch(this.form.orderType){
            case "直拨":
                return this.z_rules;
            case "外发":
                return this.w_rules;
            case "内转":
                return this.n_rules;
        }
    }
  },
  async created() {
    var pList = [];
    pList = await this.$api.shortList({});
    this.partnerList = pList;

    var cList = [];
    cList = await this.$api.carInfoList({});
    this.carInfoList = cList.data;

    var dList = [];
    dList = await this.$api.driverFastList({});
    this.driverList = dList;

    this.form = await this.$api.OrderGet({
      orderId: this.$route.query.orderId,
    });

    this.form.imageInfoVOS.forEach((item) => {
      if (item.imageUrl.length >= 2) {
        for (let i of item.imageUrl) {
          this.bigImages.push(i);
          this.bigImgList.push({
            gmtCreate: item.gmtCreate,
            gmtModified: item.gmtModified,
            imageRemark: item.imageRemark,
            imageType: item.imageType,
            imageUrl: i,
            orderId: item.orderId,
            tenantId: item.tenantId,
            uploaderPhone: item.uploaderPhone,
            userIdentity: item.userIdentity,
            userName: item.userName,
          });
        }
      } else {
        let img = item.imageUrl[0];
        this.bigImages.push(img);
        this.bigImgList.push(item);
      }
    });

    if (this.form.orderType === "外发" || this.form.orderType === '直拨') {
      // 对起运地、目的地的直辖市特殊处理
      var provinceCode;
      var cityCode;
      var countyCode;
      var sendProvinceName = this.form.sendProvinceName;

      var receiveProvinceCode;
      var receiveCityCode;
      var receiveCountyCode;
      var receiveProvinceName = this.form.receiveProvinceName;

      try {
        if (this.zxsArray.indexOf(sendProvinceName) !== -1) {
          provinceCode = TextToCode[this.form.sendProvinceName].code;
          cityCode = TextToCode[this.form.sendProvinceName]["市辖区"].code;
          countyCode =
            TextToCode[this.form.sendProvinceName]["市辖区"][
              this.form.sendCountyName
            ].code;
        } else {
          provinceCode = TextToCode[this.form.sendProvinceName].code;
          cityCode =
            TextToCode[this.form.sendProvinceName][this.form.sendCityName].code;
          countyCode =
            TextToCode[this.form.sendProvinceName][this.form.sendCityName][
              this.form.sendCountyName
            ].code;
        }
        this.form.adminRegions = [provinceCode, cityCode, countyCode];
        console.log(this.form.adminRegions,'-----------');
        if (this.zxsArray.indexOf(receiveProvinceName) !== -1) {
          receiveProvinceCode = TextToCode[this.form.receiveProvinceName].code;
          console.log(this.form.receiveCityName);
          receiveCityCode =
            TextToCode[this.form.receiveProvinceName]["市辖区"].code;
          console.log(receiveCityCode);
          receiveCountyCode =
            TextToCode[this.form.receiveProvinceName]["市辖区"][
              this.form.receiveCountyName
            ].code;
        } else {
          receiveProvinceCode = TextToCode[this.form.receiveProvinceName].code;
          receiveCityCode =
            TextToCode[this.form.receiveProvinceName][this.form.receiveCityName]
              .code;
          receiveCountyCode =
            TextToCode[this.form.receiveProvinceName][
              this.form.receiveCityName
            ][this.form.receiveCountyName].code;
        }
        this.form.adminRegions_r = [
          receiveProvinceCode,
          receiveCityCode,
          receiveCountyCode,
        ];
        console.log(this.form.adminRegions_r,'=============')
      } catch (error) {
        console.log(error);
      }
    }
  },

  // async mounted () {
  //   this.form = await this.$api.OrderGet({
  //     orderId: this.$route.query.orderId
  //   })
  //   console.log(this.form)
  // }
};
</script>

<style lang='sass' scoped>
.empty /deep/ .el-form-item__label
  color: #fff
   
/deep/ .el-form-item--mini.el-form-item
  display: flex;
  width: 100%;
/deep/ .el-form-item--mini .el-form-item__content
  flex: 1
/deep/ .el-form-item--mini .el-form-item__label
  line-height: 32px
  height: 32px

/deep/ .el-select,
/deep/ .el-cascader--small,
/deep/ .el-date-editor.el-input
  width: 100%
/deep/ .el-input__inner
  height: 28px;
  line-height: 28px;


/deep/ .el-image-viewer__close
  color: #fff
/deep/ .el-image-viewer__next,
/deep/ .el-image-viewer__prev
  border: 3px solid #fff
</style>
